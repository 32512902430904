import { Injectable } from '@angular/core';
import { collection, Firestore, query, where, orderBy, startAt, endAt, getDocs } from '@angular/fire/firestore';

import { PostService } from '../../post/service/post.service';

import { geohashQueryBounds, distanceBetween } from 'geofire-common';
import { BehaviorSubject } from 'rxjs';
import { getDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private searchResults$ = new BehaviorSubject(null);

  constructor(private postService: PostService, private firestore: Firestore) { }

  getSearchResults() {
    return this.searchResults$.asObservable();
  }

  async search(zipcode: string) {
    const { results: [ geoData ] } = await this.postService.getLatLong(zipcode).toPromise();

    if (geoData) {
      const lat = geoData.geometry.location.lat();
      const lng = geoData.geometry.location.lng();
      const radiusInM = 50 * 1000;
      const bounds = geohashQueryBounds([lat, lng], radiusInM);

      const promises = [];
      const collectionRef = collection(this.firestore, 'garagesales');
      for (const b of bounds) {
        const q = query(collectionRef, orderBy('geoHash'), startAt(b[0]), endAt(b[1]));
        promises.push(getDocs(q));
      }

      Promise.all(promises).then((snapshots) => {
        const matchingDocs = [];

        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const latitude = doc.get('latitude');
            const longitude = doc.get('longitude');

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween([latitude, longitude], [lat, lng]);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM) {
              matchingDocs.push(doc);
            }
          }
        }

        return matchingDocs;
      }).then(async (matchingDocs) => {
        this.searchResults$.next(matchingDocs);
      });
    }
  }
}
