import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

// Service
import { LoginService } from './login/service/login.service';
import { PostService } from './post/service/post.service';
import { SearchService } from './search-results/service/search.service';

// Icons
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isLogedin = false;
  faTwitter = faTwitter;
  faFacebook = faFacebook;
  searchForm: FormGroup;

  constructor(private breakpointObserver: BreakpointObserver, private loginService: LoginService,
              private fb: FormBuilder, private router: Router, private postService: PostService, private searchService: SearchService) {}

  ngOnInit() {
    this.checkLogin();
    this.initSearchFomr();
  }

  logout() {
    this.loginService.logout().then();
  }

  /**
   * Move to search service
   */
  async search() {
    if (this.searchForm.valid) {
      const { zipcode } = this.searchForm.value;
      await this.searchService.search(zipcode);

      this.router.navigate(['/search-results']);
    }
  }

  private checkLogin() {
    this.loginService.checkLogin().subscribe(resp => {
      if (resp?.email) {
        this.isLogedin = true;
      } else {
        this.isLogedin = false;
      }
    });
  }

  private initSearchFomr() {
    this.searchForm = this.fb.group({
      zipcode: ['', [Validators.required]]
    });
  }
}
