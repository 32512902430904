import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { traceUntilFirst } from '@angular/fire/performance';
import { collection, collectionData, doc, docData, Firestore, addDoc, query, where, getDoc } from '@angular/fire/firestore';
import { getDownloadURL, ref, uploadBytes, Storage } from '@angular/fire/storage';
import { MapGeocoder } from '@angular/google-maps';

import {v4 as uuidv4} from 'uuid';
import { geohashForLocation } from 'geofire-common';

// Models
import { Listing } from '../model/post-model';

import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient, private firestore: Firestore, private storage: Storage,
              private geocoder: MapGeocoder) {
  }

  getLatLong(address: string) {
    return this.geocoder.geocode({ address });
  }

  getImageMap(lat: number, long: number) {
    return this.http.get(`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${long}&zoom=12&size=300x300&key=${environment.googleMapsAPI}`, { responseType: 'blob' });
  }

  savePost(postData: Listing, mapImage: Blob) {
    // Upload image
    const myuuid = uuidv4();
    const storageRef = ref(this.storage, `maps/${myuuid}.png`);

    postData.geoHash = geohashForLocation([postData.latitude, postData.longitude]);
    uploadBytes(storageRef, mapImage).then(async (snapshot) => {
      postData.imgUrl = await getDownloadURL(snapshot.ref);
      const collectionRef = collection(this.firestore, 'garagesales');
      return await addDoc(collectionRef, postData);
    });
  }

  getAllActivePosts() {
    const collectionRef = collection(this.firestore, 'garagesales');
    const q = query(collectionRef, where('isActive', '==', true));
    return q;
  }

  getPostsByState(state: string) {
    const collectionRef = collection(this.firestore, 'garagesales');
    const q = query(collectionRef, where('state', '==', state));
    return q;
  }

  async getPostByID(postId: string) {
    const docRef = doc(this.firestore, `garagesales/${postId}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }
}
