<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <ng-container *ngIf="!isLogedin">
      <mat-list>
        <a mat-list-item routerLink="/login">Login</a>
        <a mat-list-item routerLink="/register">Register</a>
      </mat-list>
    </ng-container>
    <ng-container *ngIf="isLogedin">
      <mat-list>
        <a mat-list-item routerLink="/myaccount">My Account</a>
        <a mat-list-item (click)="logout()" class="pointerCursor">Logout</a>
    </mat-list>
    </ng-container>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span routerLink="/">
        <img src="../assets/images/logosmall.png" alt="GarageSaleTime.com" class="pointerCursor">
      </span>

      <div class="searchContainer">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <mat-form-field class="example-form-field">
            <mat-label>Zipcode</mat-label>
            <input matInput type="text" formControlName="zipcode">
            <button mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>

      <span class="spacer"></span>
      <ng-container *ngIf="!isLogedin && (isHandset$ | async) === false">
        <a routerLink="/login" class="linkAction linkPadding">Login</a>
        <a routerLink="/register" class="linkAction">Register</a>
      </ng-container>
      <ng-container *ngIf="isLogedin && (isHandset$ | async) === false">
        <a routerLink="/myaccount" class="linkAction linkPadding">My Account</a>
        <a (click)="logout()" class="linkAction">Logout</a>
      </ng-container>

    </mat-toolbar>

    <div class="mainBody">
      <router-outlet></router-outlet>

      <footer fxLayout="row" class="footer">
        <div fxFlex="90">
          <p>Copyright &copy; 2021 Trex Green Software, LLC.</p>
          <p>
            <a routerLink="/terms-of-service">
              Terms of Service
            </a>
          </p>
        </div>

        <div fxFlex="10">
          <a href="https://twitter.com/garagesaletime" target="_blank">
            <fa-icon [icon]="faTwitter"></fa-icon>
          </a>
        </div>
      </footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
