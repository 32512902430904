<div style="overflow: hidden;">
  <div fxFill fxLayout="column" class="mainBody">
    <div fxFlex fxLayout fxLayoutGap="20px">
      <div fxFlex="100" fxShow="false" fxShow.xs="true" fxShow.sm="true">
        <div class="postButton" routerLink="/post">
          Hosting a Garage Sale?
        </div>
      </div>
    </div>
    <div fxFlex fxLayout fxLayoutGap="20px">
      <div fxFlex="15" fxShow.xs="false" fxShow.sm="false" fxShow>
        <div class="postButton" routerLink="/post">
          Hosting a Garage Sale?
        </div>
        <h3>Available States</h3>
        <mat-chip-list aria-label="State selection">
          <mat-chip class="actionClick" (click)="getAllPosts()">
            All States
          </mat-chip>
          <mat-chip class="actionClick" *ngFor="let state of states" (click)="filterState(state.abbreviation)">
            {{state.name}}
          </mat-chip>
        </mat-chip-list>
      </div>
      <div fxFlex="85" fxFlex.gt-xs="100">
        <h3>Latest Garage Sale</h3>
        <div class="allListing">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let list of allListings">
              <mat-card class="mat-elevation-z4 actionClick" [routerLink]="['sale', list.id]">
                <mat-card-header>
                  <mat-card-title>{{ list.title }}</mat-card-title>
                  <mat-card-subtitle>
                    {{ list.city }}, {{ list.state }}, {{ list.zipcode }}
                  </mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image src="{{ list.imgUrl }}" alt="{{ list.title }}">
                <mat-card-actions>
                  <button mat-button>Details</button>
                </mat-card-actions>
              </mat-card>
            </div>

            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="allListings.length === 0" style="text-align: center; font-weight: bold;">
              No Listing Found. <a routerLink="/post">Click here</a> to post one!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
