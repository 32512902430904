import { Injectable } from '@angular/core';
import { Auth, authState, signOut, User, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private auth: Auth) { }

  get userId() {
    const user = this.auth.currentUser;
    return user.uid;
  }

  async emailLogin(userName: string, password: string) {
    return await signInWithEmailAndPassword(this.auth, userName, password);
  }

  checkLogin() {
    return authState(this.auth);
  }

  logout() {
    return signOut(this.auth);
  }
}
