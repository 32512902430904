<div fxLayout="row" fxLayoutGap="20px" class="container">
  <div fxFlex="100">
    <p align="left"><strong>Terms of Use Agreement </strong></p>
    <p>This agreement contains all of the terms and conditions (&quot;Terms&quot;) between   garagesaletime.com and you (&quot;User&quot;) and governs the use of the services   offered at the garagesaletime.com Web Site (&quot;Site&quot;). You must read, agree with   and accept all of the terms and conditions contained in this Terms of Use   Agreement and the Privacy Policy, which include those terms and conditions   expressly set out below and those incorporated by reference, before you may   become a member of garagesaletime.com. Use of the Site constitutes an   agreement to these Terms. By using this Site, all Users do hereby represent,   warrant, understand, agree to and accept all terms and conditions contained   herein. We reserve the right, at our discretion, to update or revise these   Terms. Please check the Terms periodically for changes. Your continued use of   this Site following the posting of any changes to the Terms constitutes   acceptance of those changes. If you do not agree to be bound by the terms and   conditions of this Agreement, do not use or access our services.</p>
    <p><strong>Use of Service and Information</strong></p>
    <p>garagesaletime.com is a communications platform for enabling the connection   between users hosting a garage sale (&quot;Sellers&quot;) and customers seeking a garage sale  (&quot;Customers&quot;). The garage sale listed on the Site are listings only and are   not recommendations or referrals. The information provided by   garagesaletime.com is believed to have come from reliable sources, including   the individuals and facilities themselves or those sources open to the public.   Users do hereby represent, understand and expressly agree that   garagesaletime.com does not have control over the quality, timing, legality or   any other aspect whatsoever of the services actually delivered by the   Sellers, nor of the integrity, responsibility or any of the actions   whatsoever of the Sellers, Customers, and any other User of the Site. All   Users, including both Sellers and Customers, do hereby expressly agree not to   hold garagesaletime.com, its officers, directors, employees, agents and third   parties liable for any instruction, advice or services delivered which   originated through the Site and garagesaletime.com expressly disclaims any   liability whatsoever for any damage, suits, claims, and/or controversies that   have arisen or may arise, whether known or unknown there from.</p>
    <p><strong>GarageSaleTime.com is Only a Service </strong></p>
    <p>garagesaletime.com is a communications platform for enabling the connection   between Sellers and Customers. garagesaletime.com does not take part in the   interaction between Sellers and Customers. garagesaletime.com does   not have control over the quality, timing or legality of services actually   delivered by its Sellers and Customers. garagesaletime.com makes no   representations about the suitability, reliability, timeliness, and accuracy of   the childcare provided by Sellers or Customers through the Site whether in   public, private or offline interactions. Due to the nature of the Internet,   garagesaletime.com cannot confirm that each Sellers or Customers is who they   claim to be. garagesaletime.com does not assume any responsibility for the   accuracy or reliability of this information or any information on this Site.   garagesaletime.com does not assume and expressly disclaims any liability that   may result from the use of this information.</p>
    <p><strong>Release</strong></p>
    <p>Because garagesaletime.com is not involved in the actual contact between   Users, in the event that you have a dispute with one or more Users, you release   garagesaletime.com officers, directors, employees, agents and third parties   from claims, demands or damages (actual or consequential) of every kind and   nature, known and unknown, suspected and unsuspected, disclosed and undisclosed,   arising out of or in any way connected with such disputes.</p>
    <p><strong>COMPLIANCE WITH THE CHILDREN'S ONLINE PRIVACY PROTECTION ACT OF   1998</strong></p>
    <p>garagesaletime.com is intended for individuals 13 or over. We have made an   effort to specifically limit garagesaletime.com appeal to individuals of age   13 or over. garagesaletime.com will not knowingly collect any information from   children under 13. If you are a Seller or Customer, you must identify your age during   the registration process. garagesaletime.com takes the Children's Online   Privacy Protection Act of 1998 (COPPA) as well as all FTC regulations very   seriously. We are also strong supporters and maintain strict compliance with the   Rules and Regulations set forth there under. That being said, we do not assume   any responsibility for any misrepresentations regarding a Seller or Customer age or   parental consent when using this Site. Should we determine that a Seller or Customer   provided any false information to us when using this Site, the Seller or Customer   membership will be terminated immediately.</p>
    <p><strong>Third Party Website </strong></p>
    <p>This Site may produce automated search results or otherwise link you to other   sites on the Internet. These sites may contain information or material that some   people may find inappropriate or offensive. These other sites are not under the   control of garagesaletime.com, and you acknowledge that garagesaletime.com   is not responsible for the accuracy, copyright compliance, legality, decency, or   any other aspect of the content of such sites AND THAT garagesaletime.com   makes no representations regarding these sites. The inclusion of such a link   does not imply endorsement of the site by garagesaletime.com or any   association with its operators.<br />
    </p>
    <p>garagesaletime.com cannot ensure that you will be satisfied with any   products or services that you purchase from a third-party site that links to the   garagesaletime.com Site because these sites are owned and operated by   independent companies and/or retailers. We do not endorse any of the goods or   services, nor have we taken any steps to confirm the accuracy or reliability of   any of the information contained in such third-party sites. garagesaletime.com   does not make any representations or warranties as to the security of any   information (including, without limitation, credit card and other personal   information) you might be requested to give any third party. All Users,   including both Parents and Babysitters, do hereby expressly agree not to hold   garagesaletime.com, its officers, directors, employees, agents and third   parties liable with respect to such sites and activity. We strongly encourage   you to make whatever investigation you feel necessary or appropriate before   proceeding with any online or off-line transaction with any of these third   parties.</p>
    <p><strong>User's Grant of Limited License </strong></p>
    <p>By posting or submitting content to this Site, you:</p>
    <ol>
    <li>grant garagesaletime.com and its affiliates and licensees the right to   use, reproduce, display, perform, adapt, modify, distribute, have distributed,   and promote the content in any form, anywhere and for any purpose; and </li>
    <li>warrant and represent that you own or otherwise control all of the rights to   the content and that public posting and use of your content by   garagesaletime.com will not infringe or violate the rights of any third   party.<br />
    </li>
    </ol>
    <p><strong>Use of Material</strong></p>
    <p>The contents of the Site, including, but not limited to, text, graphics,   sounds, images and other material (&quot;Material&quot;), are owned by   garagesaletime.com and third party service providers (&quot;Service Providers&quot;) and   are protected by United States and foreign intellectual property laws.   Unauthorized use of the Material may violate copyright, trademark and other   laws.<br />
    </p>
    <p>garagesaletime.com authorizes you to view and download a single copy of the   Material on the Site solely for your personal, non-commercial use, provided you   retain all copyright and other proprietary notices contained in the original   Material on any copy you make of the Material. You may not sell or modify the   Material or reproduce, display, publicly perform, distribute, or otherwise use   the Material in any way for any public or commercial purpose. The Material may   not be used on any other website or in a networked computer environment for any   purpose. Systematic retrieval of data or other content from this Site to create   or compile, directly or indirectly, a collection, compilation, database or   directory without written permission from garagesaletime.com is prohibited. In   addition, use of the content or materials for any purpose not expressly   permitted in these Terms is prohibited.</p>
    <p>You may not copy or adapt the HTML or other code that garagesaletime.com   uses to generate its pages. This code is also protected by garagesaletime.com   copyright and other intellectual property rights.</p>
    <p><strong>Site Use</strong></p>
    <p><em>General Rules</em>: You may not use the   Site in order to transmit, distribute, store or destroy material (a) in   violation of any applicable law or regulation, (b) in a manner that will   infringe the copyright, trademark, trade secret or other intellectual property   rights of others or violate the privacy, publicity or other personal rights of   others, or (c) that is defamatory, obscene, threatening, abusive, hateful, or   otherwise objectionable, in our sole discretion.<br />
    <br />
    <em>Security Rules</em>: You may not violate or   attempt to violate the security of the Site or use the Site to violate the   security of other sites by any method, including, without limitation: (a)   accessing data not intended for you or logging into a server or account which   you are not authorized to access, (b) attempting to probe, scan or test the   vulnerability of a system or network or to breach security or authentication   measures without proper authorization, (c) attempting to interfere with service   to any User of the Site, host or network, including, without limitation, via   means of submitting a virus to the Site, overloading, &quot;flooding,&quot; &quot;spamming,&quot;   &quot;mailbombing,&quot; or &quot;crashing&quot; the Site, (d) sending unsolicited e-mail, including   promotions and/or advertising of products or services, or (e) forging any Site   packet header or any part of the header information in any e-mail or newsgroup   posting. Violations of system or network security may result in civil or   criminal liability. garagesaletime.com may investigate violations of the Terms   and may involve and cooperate with law enforcement authorities in prosecuting   users who are involved in such violations.<br />
    <br />
    <em>Specific Prohibited Uses</em>: Without   limitation, garagesaletime.com specifically prohibits any use of the Site, and   you may not use the Site to distribute or otherwise publish, without our prior   approval, any material containing any solicitation of funds, promotion,   advertising, or solicitation for goods or services; solicit other users of the   Site to join or become members of any online or other service; or delete or   revise any material posted by any other person or entity.<br />
    <br />
    <br />
    <strong>Trademarks</strong></p>
    <p>The garagesaletime.com logo and the tageline, &quot;Find a babysitter today&quot; are   trademarks of garagesaletime.com. This list is not a comprehensive list of all   garagesaletime.com's trademarks or service marks. The absence of a trademark,   product or service name or logo from this list does not constitute a waiver of   our trademark or other intellectual property rights concerning that name or   logo.<br />
    <br />
    All other products, services, and company names mentioned in the   Site may be trademarks or service marks of their respective owners.</p>
    <p><strong>Linking to the Site</strong></p>
    <p>If you would like to link to the Site, you must comply with the following. A   website that links to the garagesaletime.com:</p>
    <ol>
    <li>may link only to home page of the Site (www.garagesaletime.com); </li>
    <li>should not frame or create a browser or border environment around the Site; </li>
    <li>should not imply that garagesaletime.com is endorsing your website or your   products or services; </li>
    <li>should not present false information about products or services on the Site; </li>
    <li>should not use our trademarks or logos without prior permission from   garagesaletime.com; and </li>
    <li>should not contain content that we might consider distasteful or offensive. </li>
    </ol>
    <p><br />
    <strong>Submissions</strong></p>
    <p>By submitting content to the Site, including information for a profile as a   Seller, you grant us and our affiliates the royalty-free,   worldwide, perpetual, irrevocable, non-exclusive right and license (including a   waiver of any moral rights) to use, reproduce, modify, adapt, publish,   translate, create derivative works from, distribute, communicate to the public,   perform and display the content (in whole or in part) and/or to incorporate it   in other works in any form, media, or technology now known or later developed,   for the full term of any rights that may exist in such content. You also warrant   that the holder of any rights, including moral rights, has validly and   irrevocably granted you the right to grant the license to us stated above. You   also permit any user of the Site to access, store, use, display, and copy such   content for personal use.<br />
    <br />
    <strong>Liability</strong></p>
    <p>The Material may contain inaccuracies or typographical errors. We make no   representations about the accuracy, reliability, completeness, or timeliness of   the Site or the Material. The use of the Site and the Material is at your own   risk. We reserve the right, at our discretion, to modify, add, or remove   portions of the Site at anytime without notice. You acknowledge and agree that   you are solely responsible for the form, content and accuracy of any material   you place on the Site. <br />
    </p>
    <p>GARAGESALETIME.COM DOES NOT WARRANT THAT THE SITE WILL OPERATE ERROR-FREE   OR THAT THE SITE OR ITS SERVER ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL   MECHANISMS. IF YOUR USE OF THE SITE OR THE MATERIAL RESULTS IN THE NEED FOR   SERVICING OR REPLACEMENT OF YOUR OR ANOTHER'S EQUIPMENT OR DATA, WE ARE NOT   RESPONSIBLE FOR THOSE COSTS.</p>
    <p>THE SITE AND THE MATERIAL ARE PROVIDED ON AN &quot;AS IS&quot; BASIS WITHOUT ANY   WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE   DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,   IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND   NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE SPECIFICALLY DISCLAIM ANY   WARRANTY (A) THAT THE SITE WILL OPERATE UNINTERRUPTED OR ERROR-FREE, (B) THAT   DEFECTS WILL BE CORRECTED, (C) THAT THERE ARE NO VIRUSES OR OTHER HARMFUL   COMPONENTS, (D) THAT THE SECURITY METHODS EMPLOYED WILL BE SUFFICIENT, AND (E)   REGARDING CORRECTNESS, ACCURACY, OR RELIABILITY. </p>
    <p>TO THE FULLEST EXTENT PERMITTED BY LAW, GARAGESALETIME.COM DISCLAIMS ANY   WARRANTIES FOR SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED ON THE SITE OR   RECEIVED THROUGH ANY LINKS PROVIDED ON THE SITE, AS WELL AS FOR ANY INFORMATION   OR ADVICE RECEIVED THROUGH THE SITE OR THROUGH ANY LINKS PROVIDED ON THE SITE.   GARAGESALETIME.COM SIMILARLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY   LAW, ANY WARRANTIES FOR ANY INFORMATION OR ADVICE OBTAINED THROUGH THE SITE. </p>
    <p>Some jurisdictions do not allow the disclaimer of implied warranties. In such   jurisdictions, the foregoing disclaimers may not apply to you insofar as they   relate to implied warranties.</p>
    LIMITATION OF   LIABILITY AND REMEDIES<br />
    THE MAXIMUM AGGREGATE LIABILITY OF   GARAGESALETIME.COM UNDER ANY CONTRACTUAL, NEGLIGENCE, STRICT LIABILITY, OR   OTHER THEORY WILL BE LIMITED EXCLUSIVELY TO THE GREATER OF (I) $1,000 OR (II) A   REFUND OF PAYMENTS WE RECEIVED FROM YOU.<br />
    <p>NEITHER WE NOR OUR SERVICE PROVIDERS SHALL BE LIABLE FOR (I) ANY INDIRECT,   SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF THE USE   OF OR INABILITY TO USE THE SITE OR ANY INFORMATION PROVIDED ON OR THROUGH THE   SITE, OR ANY OTHER HYPERLINKED SITE, INCLUDING WITHOUT LIMITATION, ANY LOST   PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA, EVEN IF WE HAVE   BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (II) ANY CLAIM ATTRIBUTABLE   TO ERRORS, OMISSIONS OR OTHER INACCURACIES IN THE SITE, THE MATERIALS OR ANY   HYPERLINKED SITE.</p>
    <p>SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON   OF SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED ON THE SITE OR RECEIVED   THROUGH ANY LINKS PROVIDED ON THE SITE, AS WELL AS BY REASON OF ANY INFORMATION   OR ADVICE RECEIVED THROUGH OR ADVERTISED ON THE SITE OR RECEIVED THROUGH ANY   LINKS PROVIDED ON THE SITE.</p>
    <p>In some jurisdictions, limitations of liability are not permitted. In such   jurisdictions, the foregoing limitation may not apply to you.</p>
    <p><strong>Indemnification</strong></p>
    <p>Upon a request by garagesaletime.com, you agree to defend, indemnify, and   hold harmless garagesaletime.com and its subsidiary and other affiliated   companies, and their employees, contractors, officers, and directors from all   liabilities, claims, and expenses, including attorney's fees, that arise from   your use or misuse of this Site. garagesaletime.com reserves the right, at it   own expense, to assume the exclusive defense and control of any matter otherwise   subject to indemnification by you, in which event you will cooperate with   garagesaletime.com in asserting any available defenses.<br />
    <br />
    <strong>Severability and Integration</strong></p>
    <p>Unless otherwise specified herein, this agreement constitutes the entire   agreement between you and garagesaletime.com with respect to this Site and   supersedes all prior or contemporaneous communications and proposals (whether   oral, written, or electronic) between you and garagesaletime.com with respect   to this Site. If any part of these Terms is held invalid or unenforceable, that   portion shall be construed in a manner consistent with applicable law to   reflect, as nearly as possible, the original intentions of the parties, and the   remaining portions shall remain in full force and effect.<br />
    <br />
    <strong>Termination</strong></p>
    <p>garagesaletime.com reserves the right, in its sole discretion, to terminate   your access to all or part of this Site, with or without cause and/or notice.   garagesaletime.com reserves the right, at its sole discretion and without   liability to you, to immediately terminate your ability to access the Site   and/or any other service we may provide to you, with or without cause. Without   limiting the foregoing, we may terminate your access if we learn you have   provided false or misleading information, interfered with other Users or the   administration of our services, or otherwise not complied with any of these   Terms.</p>

  </div>
</div>
