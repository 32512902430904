import { Component, OnInit } from '@angular/core';
import { onSnapshot,  } from '@angular/fire/firestore';

// Model & Templates
import { StatesList } from '../../assets/helper/states';
import { Listing } from '../post/model/post-model';

// Service
import { PostService } from '../post/service/post.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  states = StatesList;
  allListings: Listing[] = [];

  constructor(private postService: PostService) { }

  ngOnInit(): void {
    this.allListings = [];
    this.getAllPosts();
  }

  filterState(abbreviation: string) {
    this.allListings = [];
    onSnapshot(this.postService.getPostsByState(abbreviation), (docs) => {
      docs.forEach(doc => {
        // @ts-ignore
        this.allListings = [...this.allListings, doc.data()];
      });
    });
  }

  getAllPosts() {
    this.allListings = [];
    onSnapshot(this.postService.getAllActivePosts(), (docs) => {
      docs.forEach(doc => {
        const tempList = doc.data();
        tempList.id = doc.id;

        // @ts-ignore
        this.allListings = [...this.allListings, tempList];
      });
    });
  }
}
